<template>
  <div>
    <main-modal
        :active="visibleDetail"
        :bodyPadding="'0 50px'"
        @onClose="onClose">
      <template v-slot:modalHeader>
        <a-button
            @click="onClose()"
            class="back-btn button-without-animation">
          <i class="anticon"><arrow/></i>
          {{ $t('users.users_list') }}</a-button>
      </template>
      <template v-slot:modalBody>
        <DetailUserFrom
            :data="data"
            @onClose="onClose"
        />
      </template>
    </main-modal>
    <div v-if="getRole === 2 && detailOrganization && routeName === 'Profile'" class="detail-organization-wrapper">
      <DetailOrganizationForm
        v-if="detailOrganization"
        :data="detailOrganization"
        :canDelete="false"
        @onClose="onClose"
      />
    </div>
  </div>
</template>

<script>
import MainModal from '@/components/MainModal/MainModal'
import arrow from '@/assets/icons/back.svg?inline'
import DetailUserFrom from "@/views/admin/DetailUserForm/DetailUserForm.vue";
import DetailOrganizationForm from "@/views/admin/DetailOrganizationForm/DetailOrganizationForm";

export default {
  name: 'UserProfile',
  components: {
    MainModal,
    arrow,
    DetailUserFrom,
    DetailOrganizationForm
  },
  props: ['visibleDetail', 'data'],
  computed: {
    getRole() {
      return parseInt(this.$store.getters['getRole'])
    },
    organizationId() {
      return this.$store.getters['getOrganizationId']
    },
    detailOrganization() {
      return this.$store.getters['detailOrganization/getOrganizationDetails']
    },
    routeName() {
      return this.$route.name;
    }
  },
  async mounted() {
    if (this.organizationId && !Object.keys(this.detailOrganization).length) {
      await this.$store.dispatch('detailOrganization/GetOrganization', this.organizationId);
    }
  },
  watch: {
    routeName: {
      immediate: true,
      handler(name) {
        if (name === 'Profile' && !Object.keys(this.detailOrganization).length) {
          this.$store.dispatch('detailOrganization/GetOrganization', this.organizationId);
        }
      }
    }
  },
  methods: {
    onClose() {
      this.$store.commit('detailUser/SET_USER_DETAIL', {})
      this.$emit('detailModalState', false)
      this.$emit('updateList')
    }
  }
}
</script>

<style lang="less">
.user-profile {
  max-width: 1500px;
  padding: 100px;
}
.detail-organization-wrapper {
  padding: 111px;
  .lock-mode-field {
    margin-bottom: 65px;
  }
  .custom-error-lock-mode {
    .custom-explain {
      top: -65px;
    }
  }
  .charge-mode {
    display: flex;
  
    > label:first-of-type {
      margin-right: 20px;
    }
    > label:nth-of-type(3n) {
      margin-left: 20px;
    }
  
    .ant-radio-button-wrapper {
      height: auto;
      width: 50%;
      border-radius: 8px;
      padding: 13px 10px;
      display: flex;
      align-items: center;
      line-height: 1.4;
      background: transparent;
      border: 1px solid #DDE1E5;
      box-shadow: none;
  
      &:not(:first-child)::before {
        content: none;
      }
  
      &-checked {
        &:not(.ant-radio-button-wrapper-disabled) {
          border: 2px solid #F6CE54;
  
          &:first-child {
            border: 2px solid #F6CE54;
          }
  
          &:hover {
            box-shadow: none;
          }
  
          &:focus-within {
            outline: none;
          }
        }
      }
    }
  }
  .ac-mode {
    > label:first-of-type {
      margin-left: 10px;
    }
  }
}
</style>


